import { graphql, Link } from 'gatsby';
import React from 'react';
import slugify from 'slugify';
import Layout from '../components/Layout';
import BlogPostCard from '../components/template/BlogPostCard';
import CategoryHeader from '../components/template/CategoryHeader';

export default function BlogCategoryTemplate({ data, pageContext }) {
  const { page, totalPages, category } = pageContext;
  return (
    <Layout>
      {pageContext.category && <CategoryHeader category={pageContext.category} backLink="/" />}
      <div className="container py-12">
        {' '}
        <div className="flex flex-wrap items-stretch justify-start row">
          {data.posts.nodes.map((post) => (
            <BlogPostCard post={post} key={post.id} />
          ))}
        </div>
      </div>
      {totalPages > 1 && (
        <div className="container pb-12 -mt-4">
          {' '}
          <div className="flex items-center justify-between uppercase font-display">
            <div>
              {totalPages > page && (
                <Link
                  className="bg-gray-300 py-2 px-4 rounded font-semibold text-sm flex items-center"
                  to={`/category/${slugify(category.toLowerCase())}/${page + 1}`}
                >
                  <i className="fa fa-chevron-left inline-block mr-2" /> Older Posts
                </Link>
              )}
            </div>
            <div>
              Page <span>{page}</span> of {totalPages}
            </div>
            <div>
              {page > 1 && (
                <Link
                  className="bg-gray-300 py-2 px-4 rounded font-semibold text-sm flex items-center"
                  to={`/category/${slugify(category.toLowerCase())}/${page === 2 ? '' : page - 1}`}
                >
                  Newer Posts <i className="fa fa-chevron-right inline-block ml-2" />
                </Link>
              )}
            </div>
          </div>
        </div>
      )}
      {/* <pre>{JSON.stringify(pageContext, null, 4)}</pre> */}
    </Layout>
  );
}

export const query = graphql`
  query ($offset: Int, $postsPerPage: Int, $category: String) {
    posts: allMdx(
      skip: $offset
      limit: $postsPerPage
      filter: {
        frontmatter: {
          categories: { in: [$category] }
          layout: { regex: "/post/" }
          slug: { ne: null }
          draft: { ne: true }
        }
      }
    ) {
      nodes {
        id
        frontmatter {
          title
          excerpt
          image {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, formats: [AUTO, WEBP])
            }
          }
          slug
          date(formatString: "DD MMMM YYYY")
          author
        }
        fields {
          readingTime {
            text
          }
        }
      }
    }
  }
`;
