/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { Link } from 'gatsby';

const CategoryHeader = ({ category, backLink, label }) => (
  <div
    className="border-b border-t py-2"
    css={css`
      border-color: var(--border);
    `}
  >
    {' '}
    <div className="container">
      <div className="flex items-center justify-between">
        <div>
          {' '}
          <i className="fa fa-tags inline-block mr-2" />
          <span className="font-display">{label || 'Category'}:</span>{' '}
          <h2 className="inline-block text-lg text-primary-500 m-0 font-sans">{category}</h2>
        </div>
        <Link
          to={backLink}
          className="px-4 py-2 bg-gray-200 rounded shadow hover:shadow-md"
          css={css`
            background: var(--bgRaised);
            border-color: var(--textNormal);
          `}
        >
          <i className="fa fa-times" />
        </Link>
      </div>
    </div>
  </div>
);

export default CategoryHeader;
